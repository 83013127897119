<template>
    <v-dialog
        v-model="dialog"
        max-width="80vw"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                v-bind="attrs"
                v-on="on"
            >
                Upload Students
            </v-btn>
        </template>

        <v-expand-transition>
            <v-card
                v-show="expandFileSelect"
            >
                <v-card-title>
                    Upload Students
                    <v-btn
                        icon
                        class="ml-auto"
                        @click="close"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-file-input
                                    v-model="file"
                                    show-size
                                    label="Click to select file to upload."
                                    @change="selectFile"
                                ></v-file-input>
                            </v-col>    
                        </v-row>
                        <v-row v-if="file">
                            <v-progress-linear
                                v-model="progress"
                                color="primary"
                                height="25"
                                reactive
                            >
                                <strong>{{ progress }} %</strong>
                            </v-progress-linear>
                        </v-row>

                        <v-alert v-if="message" border="left" :color="messageColor" dark>
                            {{ message }}
                        </v-alert>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-2"
                        @click="close"
                        depressed
                    >
                        Cancel Upload
                    </v-btn>
                    <v-btn
                        class="mr-2"
                        color="primary"
                        @click="downloadTemplate"
                    >
                        Download Template
                    </v-btn>
                </v-card-actions>
                <v-overlay
                    :value="overlayDownload"
                    :absolute="true"
                    color="#fff"
                    opacity="0.4"
                >
                    <v-progress-circular                        
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-expand-transition>

        <v-expand-transition>
            <v-card
                v-show="expandConfirmUpload"
                max-height="95vh"
            >
                <v-card-title>
                    <v-row>
                        <v-col cols="4">
                            {{ uploadConfirmText }}
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                v-model="academicYear"
                                :items="academicYears"
                                outlined
                                hide-details="auto"
                                label="Academic Year"
                                item-text="academic_year"
                                item-value="academic_year_id"
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="students"                    
                        height="45vh"
                        fixed-header
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :show-select="showSelect"
                    >
                        <template v-slot:item="{ item, headers }">
                            <tr>
                                <td
                                    v-for="(h, index) in headers"
                                    :key="index"
                                >
                                    <span v-if="h.value != 'actions'">
                                        {{ item[h.value] }}
                                    </span>
                                    <span v-else>
                                        <v-icon
                                            v-if="uploadComplete && !item.error"
                                            small
                                            color="green"                            
                                        >
                                            mdi-check-all
                                        </v-icon>
                                        <v-icon
                                            v-if="uploadComplete && item.error"
                                            small
                                            color="red"                            
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions
                    class="px-6"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeUploadConfirm"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeDeleteRecords"
                    >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                    <v-btn
                        v-if="deleteRecords"
                        color="primary"
                        dark
                        small
                        class="mr-2"
                        @click="deleteSelectedRecords"
                    >
                        Delete
                    </v-btn>
                    <v-btn
                        v-if="!uploadComplete && deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="selectRecords"
                    >
                        Delete Records
                    </v-btn>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        dark
                        small
                        @click="uploadConfirm"
                    >
                        Confirm Upload
                    </v-btn>
                    <v-btn
                        v-if="uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeUploadConfirm"
                    >
                        Close
                    </v-btn>
                </v-card-actions>

                <v-overlay
                    :value="overlay"
                    :absolute="true"
                    color="#fff"
                    opacity="0.9"
                >                
                    <v-card                    
                        flat
                        width="400"
                        light
                    >
                        <v-row
                            align-content="center"
                            justify="center"
                        >
                            <v-col 
                                cols="12"
                                class="subtitle-1 text-center"
                            >
                                {{ uploadingText }}
                            </v-col>
                            <v-col 
                                cols="6"
                                v-show="!uploadComplete"
                            >
                                <div class="text-center">
                                    <strong>
                                        {{  Math.ceil(progress) }}%
                                    </strong>
                                </div>

                                <v-progress-linear
                                    v-model="progress"
                                    height="20"                        
                                    color="primary"
                                    reactive
                                >   
                                </v-progress-linear>

                                <v-overlay
                                    absolute
                                    opacity="0"
                                    color="primary"
                                ></v-overlay>

                            </v-col>
                            
                        </v-row>                    
                    </v-card>
                </v-overlay>

                <v-overlay
                    :value="overlayDeleteConfirm"
                    :absolute="true"
                    color="#fff"
                    opacity="0.9"
                >
                    <v-card 
                        class="pa-2"
                        width="300"
                        light
                    >
                        <v-row
                            align-content="center"
                            justify="center"
                        >
                            <v-col 
                                cols="12"
                                class="subtitle-1 text-center"
                            >
                                {{ deleteRecordsMessage }}
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                outlined
                                class="mr-4"
                                color="primary"
                                small
                                @click="cancelDeleteRecords"
                            >
                                Cancel
                            </v-btn>
                            <v-btn                            
                                color="primary"
                                dark
                                small
                                @click="deleteSelectedRecordsConfirm"                            
                            >
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>        
                </v-overlay>
            </v-card>
        </v-expand-transition>         
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    data: function () {
        return {
            dialog: false,
            overlay: false,
            expandFileSelect: false,
            file: null,
            progress: 0,
            message: '',
            messageColor: '',
            overlayDownload: false,
            expandConfirmUpload: false,
            uploadConfirmText: '',
            selected: [],
            students: [],
            itemsPerPage: -1,
            loading: false, 
            showSelect: false,
            uploadComplete: false,
            deleteRecords: false,
            overlayDeleteConfirm: false,
            deleteRecordsMessage: '',
            uploadingText: null,
            uploadingMessages: [
                'Please wait...',
                'Working on your request...',
                'Taking a bit long...',
                'Still working...'
            ],
            academicYears: [],
            academicYear: null,
            snackbar: {
                display: false,
                color: 'primary',
                text: null,
            }
        }
    },

    computed: {
        headers () {
            if(!this.uploadComplete){
                return [
                    {text: 'Student ID', value: 'id', width: 100},
                    {text: 'Last Name', value: 'last_name'},
                    {text: 'First Name', value: 'first_name'},
                    {text: 'SEA Reg No.', value: 'sea_number'},
                    {text: 'Form Class', value: 'form_class_id'},                
                    {text: 'DOB', value: 'date_of_birth'},               
                    {text: 'Entry Date', value: 'entry_date'},
                    {text: 'Gender', value: 'gender'},                
                    {text: 'Email Address', value: 'email_address'},
                    {text: 'Previous School', value: 'previous_school'},
                    {text: 'Home Address', value: 'address_home'},
                    // {text: 'House', value: 'house_name'},                
                    {text: '', value: 'actions', width: '50'},                
                ];
            }

            return [
                {text: 'Student ID', value: 'id', width: 100},
                {text: 'Last Name', value: 'last_name'},
                {text: 'First Name', value: 'first_name'},
                {text: 'SEA Reg No.', value: 'sea_number'},
                {text: 'Class', value: 'form_class_id'},
                {text: 'Gender', value: 'gender'},
                {text: 'DOB', value: 'date_of_birth'},               
                // {text: 'House', value: 'house_name'},                
                {text: 'Entry Date', value: 'entry_date'}, 
                {text: 'Email Address', value: 'email_address'}, 
                {text: '', value: 'actions', width: '50'},
            ]
        }
    },

    watch: {
        dialog (val) {
            console.log(val);
            if(val) {
                this.initialize();
            }
        },

        file: {
            handler (val) {
                if(val) this.uploadFile();
            }
        }
    },

    methods: {
        async initialize () {
            console.log("initializing");
            try {
                const { data } = await this.getStudentUploadAcademicYears();
                this.academicYears = data;
                this.academicYear = this.academicYears[0]?.academic_year_id;
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            this.expandFileSelect = true;
        },

        ...mapMutations({
            setNewStudents: 'students/setNewStudents',
            setStudentUploadAcademicYearId: 'students/setStudentUploadAcademicYearId',
        }),

        ...mapActions({
            downloadStudentUploadTemplate: 'editViewStudents/downloadTemplate',
            uploadStudents: 'students/uploadStudents',
            getStudentUploadAcademicYears: 'students/getStudentUploadAcademicYears',
        }),

        downloadTemplate () {
            this.overlayDownload = true;            
            this.downloadStudentUploadTemplate()
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const now = new Date().getTime();
                link.setAttribute('download', `Registration Upload-${now}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch( error => {
                if(error.response) console.log(error.response);
                else console.log(error);
                
            })
            this.overlayDownload = false;

        },       
        

        selectFile (file) {
            this.progress = 0;
            this.file = file;
            this.message = null;
        },

        uploadFile () {
            if(!this.file) {
                this.messageColor = "red"
                this.message = "Please select a file!";
                return;
            }

            this.message = "";
            this.loading = true;
            this.overlayDownload = true;

            UploadService.uploadSpreadsheet(this.file, this.academicYear, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
            .then((response) => {
                this.expandFileSelect = false;
                this.loading = false;
                this.overlayDownload = false;
                this.expandConfirmUpload = true;
                this.uploadConfirmText = "Confirm Upload Students"
                // setTimeout(() => {
                // }, 700);
                
                this.students = response.data;
            })
            .catch((error) => {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.progress = 0;
                this.message = "Could not upload the file.";
                this.messageColor = 'red'
                this.file = null;
                this.overlayDownload = false;
            })

        },

        async uploadConfirm () {
            console.log("upload confirmed");

            this.overlay = true;
            this.uploadComplete = false;
            this.uploadingText = "Uploading Students."
            let dataStudents = [];
            this.progress = 0;

            this.setStudentUploadAcademicYearId(this.academicYear);
            let totalRecords = this.students.length;

            for(let i = 0; i < totalRecords; i++) {
                try {
                    this.setNewStudents([this.students[i]]);
                    const { data } = await this.uploadStudents();
                    dataStudents.push(data[0])
                    this.progress = ((i + 1) / totalRecords) * 100
                } catch (error) {
                    error.response ? console.log(error.response) : console.log(error);
                }
                
            }

            this.mapUploadedStudents(dataStudents);
            this.uploadingText = "Upload Complete.";
            this.uploadComplete = true;
            this.overlay = false
            this.$emit("update-students");
        },

        mapUploadedStudents (data) {
            this.students =  [];
            data.forEach(value => {
                this.students.push(value.record);
            })
        },

        selectRecords () {
            this.showSelect = true;
            this.deleteRecords = true;
            this.uploadConfirmText = "Select Records To Delete"
        },

        cancelDeleteRecords () {
            this.overlayDeleteConfirm = false;
            this.deleteRecordsMessage = '';
        },

        deleteSelectedRecordsConfirm () {
            this.cancelDeleteRecords();
            this.selected.forEach(value => {                
                this.students.splice(this.students.indexOf(value), 1);
            })
            this.selected = [];
        },

        closeUploadConfirm () {
            this.dialog = false;
            this.expandConfirmUpload = false;
            this.expandFileSelect = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
            this.uploadComplete = false;
        },

        close () {
            this.dialog = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
        },
    }
}
</script>