<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="1020"
                n
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <StudentsUpload
                        v-on:update-students="initialize"
                    ></StudentsUpload>

                    <EditViewStudentsDialog
                        :initial-dialog="dialog"
                        :student-record="editedItem"
                        :form-title="formTitle"
                        :form-classes="formClasses"
                        @dialog-close="close"
                        v-on:update-students="initialize"
                    />
                    
                </v-card-title>

                <div class="pl-2">
                    <v-btn 
                        :color="current.color" 
                        :depressed="current.depressed" 
                        class="ma-2" 
                        x-small
                        tile
                        @click="filterCurrent()"
                    >
                    <v-icon v-if="currentStudents" small left light>mdi-check-circle</v-icon>
                        Current
                    </v-btn>
                        <v-btn 
                        :color="archive.color" 
                        :depressed="archive.depressed" 
                        class="ma-2" 
                        x-small
                        tile
                        @click="filterArchive()"
                    >
                        <v-icon v-if="!currentStudents" left light>mdi-check-circle</v-icon>
                        Archive
                    </v-btn>
                </div>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"
                        item-key="student_id"
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <!-- Dialog Change Status -->
                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                            class="mr-6"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="deleteStudentConfirm"
                                        >
                                            Change Status
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template v-slot:item="props">
                            <tr>
                                <td>
                                    <v-img
                                        :src="imageUrl(props.item.picture)"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.gender }}</td>
                                <td>{{ props.item.date_of_birth }}</td>
                                <td>
                                    <v-btn
                                        tile
                                        color="primary"
                                        @click="editStudent(props.item)"
                                        class="mr-6 mb-3"
                                        x-small
                                        block
                                    >
                                        Edit/View
                                        <v-icon
                                            x-small
                                            right
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        
                                    </v-btn>
                                    <v-btn
                                        text
                                        outlined
                                        @click="updateStudentStatus(props.item)"
                                        x-small
                                        block
                                    >    
                                        {{ props.item.student_status }}
                                        
                                    </v-btn> 
                                </td>

                                <td>    
                                    <v-btn
                                        text
                                        outlined
                                        x-small
                                        class="my-3"
                                        @click="showRegistrationForm(props.item)"
                                    >    
                                        Reg. Form
                                        <v-icon
                                            small  
                                            right                                  
                                        >
                                            mdi-file-document-outline
                                        </v-icon>
                                    </v-btn>    
                                </td>
                            </tr>
                        </template>

                    </v-data-table>

                    <v-snackbar
                        v-model="snack.display"
                        :timeout="3000"
                        :color="snack.color"
                    >
                        {{ snack.text }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack.display = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>

                    <!-- Registration Report Dialog -->
                    <v-dialog 
                        :value="dialogRegistrationReport" 
                        persistent
                        max-width="90vw"
                        
                    >
                        <v-card pa-3 flat height="90vh" style="overflow:hidden;">
                            <v-system-bar  window color="primary" dark>
                                <v-spacer></v-spacer>
                                <v-btn small icon @click="dialogRegistrationReport=!dialogRegistrationReport">
                                    <v-icon color="red">mdi-close</v-icon>
                                </v-btn>
                            </v-system-bar>

                             <iframe 
                                ref="pdf" 
                                style="width:100%; height:100%" 
                                class="report-viewer"  
                                :src="src"
                            ></iframe>              
                            <v-overlay
                                absolute
                                :value="reportViewerOverlay"
                                color="white"
                                opacity="0.60"
                            >
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                    color="primary"
                                ></v-progress-circular>
                            </v-overlay> 
                        </v-card>
                    </v-dialog>
                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import StudentsUpload from './EditViewStudentsUpload.vue';
import EditViewStudentsDialog from './EditViewStudentsDialog.vue';
export default {
    created () {
        this.initialize();
    },

    components: {
        StudentsUpload,
        EditViewStudentsDialog,
    },

    data: () => ({
        headers: [
            {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
            {text: 'ID', align: 'start',  value: 'student_id', width: '100'},
            {text: 'Name', align: 'start', sortable: false, value: 'name', width: '200'},
            {text: 'Class', align: 'start',  value: 'form_class_id', width: '100'},
            {text: 'Gender', align: 'start', sortable: false, value: 'gender', width: '80'},
            {text: 'DOB', align: 'start', value: 'data_of_birth', width: '120'},
            {text: '', align: 'start', sortable: false, value: 'actions', },
            {text: '', align: 'start', sortable: false, value: 'regForm', },
        ],
        students: [],
        search: '',
        loading: true,
        snack: {
            display: false,
            text: '',
            color: 'primary'
        },
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: '',
            picture: require("../assets/icons/student.svg")
        },
        gender: ['M', 'F'],
        formClasses: [],
        menu: false,
        date: null,
        expanded: [],
        singleExpand: false,
        overlay: false,
        saved: false,
        error: false,
        rules: [
            value => !!value || 'Required'
        ],
        formHasErrors: false,
        errorMessage: '',
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        dialogRegistrationReport: false,
        src: null,
        reportViewerOverlay: false,
        current: {
            color: 'primary',
            depressed: false,
        },
        archive: {
            color: 'gray',
            depressed: true
        },
        currentStudents: true,  
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' :  
            this.editedItem.first_name + ' '
            + this.editedItem.last_name
        },
        studentDeleted () {
            return 'Updating ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        },

        ...mapGetters({
            user: 'auth/getUser',
        }),

      
    },

    watch: {
        dialog (val) {
            console.log('Dialog: ', val)
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        date (val) {
            this.editedItem.date_of_birth = val;
        }
    },

    methods: {
        ...mapActions({
            getStudents: 'editViewStudents/getStudentsAll',
            getFormClasses: 'termReports/getFormClassesList',
            postStudent: 'editViewStudents/postStudent',
            getStudentStatus: 'editViewStudents/getStudentStatus',
            delete: 'editViewStudents/deleteStudent',
        }),
        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
            setEditPermission: 'students/setEditPermission',
        }),

        async initialize(){
            this.students = [];
            this.loading = true;
            const userPermissions = JSON.parse(sessionStorage.getItem('permissions'));
            const user = JSON.parse(this.user);
            if(user.employee_id == 'Admin'|| (userPermissions && userPermissions.some(permission => permission.permission_id == 17)) ){
                this.editStudents = true;
                this.setEditPermission(true);
            }

            console.log('edit students: ',this.editStudents);

            try {
                const [ 
                    { data: students }, 
                    { data: formClasses },
                    { data: dataStudentStatuses}
                ] = 
                await Promise.all([
                    this.getStudents(), 
                    this.getFormClasses(),
                    this.getStudentStatus()
                ]);
                
                this.mapStudents(students);
                this.formClasses = formClasses;
                this.mapStatuses(dataStudentStatuses);
               
                // console.log(this.statuses);               
                this.loading = false;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
        },

        mapStudents (data) {
            const { current_students, archived_students } = data;

            this.studentsCurrent = current_students.map(value => {
                    value.name = value.last_name + ', ' + value.first_name;
                    if(!value.picture){
                        value.picture = require("../assets/icons/student.svg")
                    }
                    return value;
                });

            this.students = this.studentsCurrent;

            this.studentsArchive = archived_students.map(value => {
                value.name = value.last_name + ', ' + value.first_name;
                if(!value.picture){
                    value.picture = require("../assets/icons/student.svg")
                }
                return value;
            })
        },    
        
        mapStatuses (data) {
            data.forEach(record => {
                if(record.detail !== 'Graduated'){
                    this.statuses.push(record);
                }
            })
        },
        
        editStudent(item){
            //console.log(`Edit ${ item }`);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            // console.log(item);
            this.date = item.date_of_birth;
            this.dialog = true;
        },
        close () {
            this.dialog = false;
            this.error = false;
            this.saved = false;
            this.date = null;
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            // Object.keys(this.editedItem).forEach(f => {
            //     if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
            //         this.$refs[f].reset()
            //     }
                
            // })
        },
        
        updateStudentStatus(item){
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
        },
        addStudent(){
            //
        },
        saveDate(date){
            this.$refs.menu.save(date);
        },
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },
        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;

            setTimeout(() => {
                this.deleting = true;
            });
            
            this.setStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.status
            });
            
            try {
                await this.delete();
                this.snack.text = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' status updated.'
                this.snack.color = 'primary'
                const { data } = await this.getStudents();
                this.mapStudents(data);
                                              
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.snack.text = `An error occured ${this.deletedItem.first_name} ${this.deletedItem.last_name} status was not updated.`
                this.snack.color = 'red'
            }

            this.deleting = false;

            setTimeout(() =>{
                this.dialogDelete = false;                 
            })
            
            setTimeout(() => {
                this.snack.display = true;
                
            })           
            
        },

        showRegistrationForm (item) {
            console.log(item)
            this.dialogRegistrationReport = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })
            this.src = process.env.VUE_APP_API_URI + "/api/registration-form/"
            + item.student_id;
        },

        imageUrl (picture){
            return `${picture}?timestamp=${new Date().getTime()}`
        },

        filterArchive () {
            this.archive.color = 'primary';
            this.archive.depressed = false;
            this.current.color = 'gray';
            this.current.depressed = true;
            this.students = [];
            this.currentStudents = false;
            this.$nextTick(() => {
                this.students = this.studentsArchive
            })
        },

        filterCurrent () {
            this.archive.color = 'gray';
            this.archive.depressed = true;
            this.current.color = 'primary';
            this.current.depressed = false;
            this.students = [];
            this.currentStudents = true;
            this.$nextTick(() => {
                this.students = this.studentsCurrent
            })
        },
    }
}
</script>

<style scoped>
    ::v-deep .v-dialog .v-dialog--active .v-dialog--persistent{
        overflow: hidden;
    }

    .v-system-bar--window .v-icon{
        margin-right: 0;
    }
</style>