<template>
    <v-dialog
        v-model="dialog"
        max-width="750px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                text
                v-bind="attrs"
                v-on="on"
                class="ml-3"
            >
                Add New Student
            </v-btn>
        </template>
        <v-card ref="form">
            <v-card-title class="pb-0">
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>                        
            <v-card-text>
                <v-container class="pt-0">
                    <v-row>
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex mb-4">
                                        <v-file-input
                                            class="pt-0"
                                            :value="pictureFile"
                                            @change="uploadPicture"
                                            prepend-icon="mdi-camera"
                                            :disabled="!admin || !editPermission"
                                            hide-input
                                            hide-details
                                        ></v-file-input>

                                        <v-btn 
                                            depressed
                                            @click="confirmDeletePicture"
                                            :disabled="!admin || !editPermission"
                                            icon
                                        >
                                        <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>

                                    <v-card
                                        flat
                                        class="mx-auto"
                                        width="200"
                                        height="200"
                                    >
                                        <v-img
                                            :src="srcPicture"
                                            width="200"
                                            height="200"
                                            @load="imageLoaded"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        v-if="isLoading"
                                                        indeterminate
                                                        color="primary"
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>

                                        <v-overlay
                                            absolute
                                            :value="overlayPicture"
                                            opacity="0.60"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                size="64"
                                                color="primary"
                                            ></v-progress-circular>
                                        </v-overlay>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <div class="d-flex flex-column mt-6">
                                <v-card v-if="pictureFile" class="mb-6" flat>
                                    <v-progress-linear
                                        v-model="progress"
                                        color="blue-grey"
                                        height="25"
                                        reactive
                                    >
                                        <strong>{{ progress }} %</strong>
                                    </v-progress-linear>
                                </v-card>

                                <v-card class="my-6" flat>
                                    <v-text-field
                                        v-model="studentRecord.student_id"
                                        hide-details
                                        label="Student ID"
                                        readonly
                                        outlined
                                        filled
                                        dense
                                        class="record-summary"
                                    ></v-text-field>
                                </v-card>

                                <v-card class="mb-4" flat>
                                    <div class="d-flex align-center">
                                        <v-text-field
                                            v-model="studentRecord.serial_number"
                                            label="Serial Number"
                                            hide-details
                                            :disabled="lockSerialNumber"
                                            outlined
                                            :filled="lockSerialNumber"
                                            dense
                                        ></v-text-field>
                                        <v-btn
                                            icon
                                            small
                                            :disabled="!admin"
                                            @click="lockSerialNumber=!lockSerialNumber"
                                        >
                                            <v-icon>{{ lockIcon }}</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>    
                            </div>

                        </v-col>

                        <v-col cols="1"></v-col>

                        <v-col cols="7">    
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        ref="first_name"
                                        v-model="studentRecord.first_name"
                                        label="First Name"
                                        hide-details="auto"
                                        :rules="rules"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        ref="last_name"
                                        v-model="studentRecord.last_name"
                                        label="Last Name"
                                        hide-details="auto"
                                        :rules="rules"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">                                            
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"                   
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            label="Date of Birth"                                                    
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            ref="picker"
                                            v-model="date"                                                
                                            min="1950-01-01"
                                            @change="saveDate"                                                
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="studentRecord.birth_certificate_pin"
                                        label="Birth Certificate Pin"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                        v-model="studentRecord.gender"
                                        :items="gender"
                                        label="Gender"
                                        hide-details
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete
                                        ref="form_class_id"
                                        v-model="studentRecord.form_class_id"
                                        :items="formClasses"
                                        item-text="id"
                                        item-value="id"
                                        label="Form Class"
                                        hide-details="auto"
                                        :rules="rules"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-container>    
            </v-card-text> 
            <v-card-actions
                class="pa-4"
            >
                <span
                    v-show="saved"
                >
                    Data saved
                    <v-icon
                        small
                        color="primary"                                        
                        class="ml-3"
                    >
                        mdi-check-all
                    </v-icon>
                </span>

                <span
                    v-show="error"
                    class="red--text"
                >
                    {{ errorMessage }}
                    <v-icon
                        small
                        color="red"
                        class="ml-3"
                    >
                        mdi-alert-circle
                    </v-icon>
                </span>

                <v-spacer></v-spacer>
                <v-btn                                    
                    outlined
                    @click="close"
                    class="mr-6"
                >
                    Close Record
                </v-btn>
                <v-btn
                    color="primary"                                    
                    dark                                   
                    @click="save"
                >
                    Save Record
                </v-btn>                                
            </v-card-actions>
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>

            <v-dialog
                v-model="dialogDelete"
                width="500"
            >
                <v-card>
                    <v-card-title class="text-h6 pl-4 white--text primary">
                        Delete Picture?
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogDelete = false">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="text-h6 font-weight-regular pa-4">
                        This action can't be undone.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="pa-4"> 
                        <v-spacer></v-spacer>
                        <v-btn class="mr-4" depressed outlined @click="dialogDelete = false" small>Cancel</v-btn>
                        <v-btn color="primary" @click="deletePicture" small>Yes Delete</v-btn>
                    </v-card-actions>
                </v-card>   
            </v-dialog>
        </v-card>       
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    props: {
        studentRecord: {
            type: Object,
            default: () => ({
                picture: require("../assets/icons/student.svg")
            })
        },
        initialDialog: Boolean,
        formTitle: {
            type: String,
            default: 'New Student'
        },
        formClasses: Array
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            editPermission: 'students/getEditPermission',
        }),

        srcPicture () {
            return `${this.studentRecord.picture}?timestamp=${new Date().getTime()}`
        },

        lockIcon () {
            if(this.lockSerialNumber) return "mdi-lock";
            return "mdi-lock-open";
        },

        admin () {
            let user = JSON.parse(this.user);
            if(user.employee_id === 'Admin') return true;
            return false;
        },
    },

    watch:{
        initialDialog: {
            handler (val){
                this.dialog = val
                console.log('studentRecord: ', this.studentRecord)  
            }
        },

        dialog: {
            handler (val) {
                console.log('dialog: ', val)
            }
        }
    },

    data: function () {
        return {
            dialog: this.initialDialog,
            overlay: false,
            saved: false,
            error: false,
            errorMessage: '',
            gender: ['Male', 'Female'],
            date: new Date().toISOString().substr(0, 10),
            menu: false,
            rules: [
                value => !!value || 'Required'
            ],
            formHasErrors: false,
            overlayPicture: false,
            pictureFile: null,
            progress: 100,
            lockSerialNumber: true,
            isLoading: true,
            dialogDelete: false,
        }
    },

    methods: {
        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
        }),

        ...mapActions({
            postStudent: 'editViewStudents/postStudent',
        }),

        async save () {
            this.overlay = true
            this.setStudent({
                student_id: this.studentRecord.student_id,
                birth_certificate_pin: this.studentRecord.birth_certificate_pin,
                date_of_birth: this.studentRecord.date_of_birth,
                first_name: this.studentRecord.first_name,
                form_class_id: this.studentRecord.form_class_id,
                gender: this.studentRecord.gender,
                last_name: this.studentRecord.last_name,
            });
            
            this.formHasErrors = false;
            //console.log(Object.keys(this.studentRecord));
            Object.keys(this.studentRecord).forEach( f => {
                if(f === 'first_name' && !this.studentRecord[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'last_name' && !this.studentRecord[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'form_class_id' && !this.studentRecord[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
               
            })

            if(this.formHasErrors){
                this.saved = false; 
                this.error = true;
                this.errorMessage = 'Required Fields Empty'; 
                this.overlay = false;
                return;
            }


            try {
                await this.postStudent();
                this.$emit('update-students');
                this.error = false;
                this.saved = true;  
            } catch (error) {
                console.log(error.response);
                this.errorMessage = 'Error Occured';
                this.saved = false; 
                this.error = true;                    
            }
           
            
            this.overlay = false;
                     
        },

        saveDate () {

        },

        async uploadPicture (file) {
            if(!file) return;
            this.overlayPicture = true;
            this.progress = 0;
            this.pictureFile = file;

            if(!this.pictureFile){
                return;
            }

            this.pictureUploadMessage = null;
            this.studentRecord.picture = null;

            try {
                const { data } = await UploadService.uploadPicture(
                this.pictureFile,
                this.studentRecord.student_id,
                'picture',
                (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    }
                )
                this.studentRecord.picture = data.picture;
                this.$emit('update-students');
            } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error);
            }

            this.pictureFile = null;
            this.overlayPicture = false;

        },


        imageLoaded () {
            console.log('image loaded...'); 
            this.isLoading = false;
        },

        confirmDeletePicture () {
            this.dialogDelete = true;
        },

        async deletePicture () {
            if(this.studentRecord.picture == null) return;
            this.dialogDelete = false;
            this.overlay = true;
            try {
                await UploadService.delete(this.studentRecord.student_id, this.studentRecord.picture, 'picture');
                this.studentRecord.picture = require("../assets/icons/student.svg");
                this.$emit('update-students');
            } catch (error) {
                if(error.response) console.log(error.response);
            }
            this.overlay = false;
        },

        close () {
            this.dialog = false;
            this.saved = false;
            this.error = false;
            this.$emit('dialog-close')
        },
    }
}
</script>
