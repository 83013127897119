import axios from 'axios'

export default {
    namespaced: true,
    state: {
        student: {},
        newStudents: [],
    },
    getters: {
        getStudent(state){
            return state.student
        },

        getNewStudents (state) {
            return state.newStudents
        }
    },
    mutations: {
        setStudent(state, value){
            state.student = value
        },

        setNewStudents (state, value) {
            state.newStudents = value
        }
    },
    actions: {

        getStudentsAll () {
            const url = '/api/students-all';
            return axios.get(url);
        },

        postStudent({ getters }){
            const url = '/api/student-profile';
            return axios.post(url, getters.getStudent);
        },

        deleteStudent({ getters }){
            const url = '/api/student';
            return axios.delete(url, { data: getters.getStudent });
        },

        getStudentStatus(){
            const url = '/api/student-status';
            return axios.get(url);
        },

        downloadTemplate () {
            return axios({
                method: 'get',
                url: '/api/student-upload-template',
                responseType: 'arraybuffer',
            });
        },

        uploadStudents ({ getters }) {
            const url = 'api/upload-new-students';
            return axios.post(url, {
                data: JSON.stringify(getters.getNewStudents),
                academic_year_id: getters.getStudentUploadAcademicYearId,
            }); 
        },
    }
}    